import { datadogRum, RumEvent } from '@datadog/browser-rum'

import { LoggerCron, LoggerTransaction } from '../constants/logger'

// TODO @michaelxuwu rename to logDatadogActionError
export const logDatadogError = (error: unknown, action: LoggerTransaction) => {
  datadogRum.addError(error, {
    action,
  })
}

export const logDatadogCronError = (error: unknown, cron: LoggerCron, context?: object) => {
  datadogRum.addError(error, {
    cron,
    ...context,
  })
}

export const beforeSendFilter = (event: RumEvent) => {
  if (event.type === 'error') {
    // Filter errors with logging from WaaS
    if (event.error.message.includes('[bugsnag]')) {
      return false
    }
    // Filter errors caused by chrome extensions
    if (event.error.handling_stack?.includes('chrome-extension://')) {
      return false
    }
  } else if (
    event.type === 'resource' &&
    event.resource &&
    event.resource.url === 'https://exceptions.coinbase.com/'
  ) {
    return false
  }
  return true
}
