import Button from '@lyra/core/components/Button'
import Notice, { NoticeStatus } from '@lyra/core/components/Notice'
import Section from '@lyra/core/components/Section'
import isServer from '@lyra/core/utils/isServer'
import { TransactionDisabledReason } from '@lyra/web/constants/auth'
import useAuth from '@lyra/web/hooks/useAuth'
import useEoaWallet from '@lyra/web/hooks/useEoaWallet'
import useTransaction from '@lyra/web/hooks/useTransaction'
import { getTransactionDisabledMessage } from '@lyra/web/utils/wallet'
import React, { useCallback, useState } from 'react'
import { StackProps } from 'tamagui'

import SignInButton from './SignInButton'

export type TransactionButtonRowProps = {
  label: string
  onSubmitTransaction: () => void
  notice?: React.ReactNode
  isLoading?: boolean
  isDisabled?: boolean
  skipGeoblockCheckDONOTUSE?: boolean
  secondaryButton?: React.ReactNode
} & StackProps

const getDisabledStatus = (reason: TransactionDisabledReason): NoticeStatus => {
  switch (reason) {
    case 'geoblocked':
    case 'kyt':
    case 'kyt-failed':
    case 'invalid-wallet':
    case 'terms':
    default:
      return 'info'
  }
}

export default function TransactionButtonRowInternal({
  label,
  notice,
  isLoading: isLoadingInput,
  isDisabled: isDisabledInput,
  skipGeoblockCheckDONOTUSE = false,
  onSubmitTransaction,
  secondaryButton,
  ...stackProps
}: TransactionButtonRowProps) {
  const { isAuthenticated, user } = useAuth()
  const { disabledReason } = useTransaction()
  const { reconnectWallet, isReady: isEoaReady } = useEoaWallet()

  const ownerAddress = user?.ownerAddress

  const isReady = !isAuthenticated || isEoaReady

  const isLoading = !isReady || isLoadingInput
  const isGeoblockSkipped = skipGeoblockCheckDONOTUSE && disabledReason === 'geoblocked'
  const isDisabled = (!!disabledReason && !isGeoblockSkipped) || isDisabledInput

  const [successNotice, setSuccessNotice] = useState<string>()

  const handleSubmitTransaction = useCallback(async () => {
    setSuccessNotice(undefined)
    await onSubmitTransaction()
  }, [onSubmitTransaction])

  if (isServer) {
    return (
      <Section.YStack {...stackProps}>
        <Button size="lg" isLoading />
        {secondaryButton}
      </Section.YStack>
    )
  }

  return (
    <Section.YStack {...stackProps}>
      {disabledReason && !isGeoblockSkipped ? (
        <Notice
          status={getDisabledStatus(disabledReason)}
          message={getTransactionDisabledMessage(disabledReason, ownerAddress)}
          width="100%"
        />
      ) : notice ? (
        notice
      ) : successNotice ? (
        <Notice status="success" message={successNotice} width="100%" />
      ) : null}
      {isAuthenticated && isReady ? (
        disabledReason === 'eoa-reconnect' ? (
          // reconnect external wallet
          <Button
            size="lg"
            color="cta"
            isSolid
            onPress={reconnectWallet}
            label="Reconnect Wallet"
          />
        ) : null
      ) : null}
      {isAuthenticated ? (
        // submit transaction
        <Button
          onPress={handleSubmitTransaction}
          size="lg"
          color="cta"
          isLoading={isLoading}
          isSolid
          label={isLoading && !isReady ? 'Connecting Wallet' : label}
          isDisabled={isDisabled}
        />
      ) : (
        // sign in
        <SignInButton size="lg" isCta />
      )}
      {secondaryButton}
    </Section.YStack>
  )
}
